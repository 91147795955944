import { f7ready } from 'framework7-vue'

const googleAnalyzeManager = {

    id: 'G-YE89XFFDLC',

    initGoogleAnalyze(){
        f7ready((f7)=>{
            f7.views.main.on('routeChange',()=>{
                this.addGtagConfig(f7);
            })
        })
    },

    addGtagConfig(f7){
        const currentRoute = f7.views.main.router.currentRoute;
        if (window.gtag) {
            window.gtag('config',this.id,{
                page_title: currentRoute.name,
                page_path: currentRoute.path
            })
        }
    }

}

export default googleAnalyzeManager
