import applicationManager from "@/applications/service/application-manager";
import googleAnalyzeManager from '@/applications/service/google-analyze-manager';
import androidManager from '@/applications/service/android-manager';
export default {
  props: ["f7Params"],
  data() {
    return {
      isAndroid: false,
      //宿主环境
      iosSwipeBackActiveArea: 30 //ios可滑动屏幕翻页的区域
    };
  },

  created() {
    this.isAndroid = window.EappApi.isAndroid();
    this.iosSwipeBackActiveArea = this.isAndroid ? 1 : 30;
  },
  mounted() {
    if (process.env.NODE_ENV !== "development") {
      androidManager.initForAndroid();
      googleAnalyzeManager.initGoogleAnalyze();
      applicationManager.getDeviceFlagAndToken();
    }
  }
};