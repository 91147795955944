import {f7} from "framework7-vue";

const androidManager = {

    //安卓沉浸式状态栏实现
    setImmersiveStatusBar() {
        window.EappApi.getStatusBarHeight(function (height) {
            const html = document.documentElement;
            if (html) {
                html.style.setProperty("--android-safe-area-top", `${height}px`);
                html.style.setProperty("--android-extra-safe-area-top", `5px`);
            }
        }, function () {
            const html = document.documentElement;
            if (html) {
                html.style.setProperty("--android-safe-area-top", `20px`);
                html.style.setProperty("--android-extra-safe-area-top", `5px`);
            }
        })
    },

    //修改安卓默认返回事件
    setAndroidBackButton() {
        document.addEventListener('backbutton', () => {
            const $ = f7.$;
            if ($('.actions-modal.modal-in').length) {
                f7.actions.close('.actions-modal.modal-in');
                return;
            }
            if ($('.dialog.modal-in').length) {
                f7.dialog.close('.dialog.modal-in');
                return;
            }
            if ($('.sheet-modal.modal-in').length) {
                f7.sheet.close('.sheet-modal.modal-in');
                return;
            }
            if ($('.popover.modal-in').length) {
                f7.popover.close('.popover.modal-in');
                return;
            }
            if ($('.popup.modal-in').length) {
                if ($('.popup.modal-in>.view').length) {
                    const currentView = f7.views.get('.popup.modal-in>.view');
                    if (currentView && currentView.router && currentView.router.history.length > 1) {
                        currentView.router.back();
                        return;
                    }
                }
                f7.popup.close('.popup.modal-in');
                return;
            }
            if ($('.login-screen.modal-in').length) {
                f7.loginScreen.close('.login-screen.modal-in');
                return;
            }

            if ($('.page-current .searchbar-enabled').length) {
                f7.searchbar.disable('.page-current .searchbar-enabled');
                return;
            }

            if ($('.page-current .card-expandable.card-opened').length) {
                f7.card.close('.page-current .card-expandable.card-opened');
                return;
            }

            const currentView = f7.views.current;
            if (currentView && currentView.router && currentView.router.history.length > 1) {
                currentView.router.back();
                return;
            }

            if ($('.panel.panel-in').length) {
                f7.panel.close('.panel.panel-in');
                return;
            }

            const currentRouteName = f7.views.current.router.currentRoute.name;
            if (['ChooseCategories','Home'].includes(currentRouteName)) {
                window.EappApi.exitApp();
            }
        }, false)
    },

    initForAndroid() {
        this.setImmersiveStatusBar();
        this.setAndroidBackButton();
    }

}

export default androidManager
